export default {
  HOME: '/',
  UNTERNEHMEN: '/unternehmen',
  LEISTUNGEN: '/leistungen',
  ACADEMY: 'https://www.dragonacademy.de/',
  DENTAL_KURSE: '/dental-kurse',
  NEWS: '/news',
  SHOP: 'https://dragon-dental.shop/',
  KONTAKT: '/kontakt',
  IMPRESSUM: '/impressum',
  DATENSCHUTZ: '/datenschutz',
}
