import React, { Fragment } from 'react'
import { format } from 'date-fns'
import { de } from 'date-fns/locale'

/** Converts Date to UTC as netlify cms saves dates in german timezone */
const parseDate = (date: string) => {
  const d = new Date(date)
  d.setTime(d.getTime() + d.getTimezoneOffset() * 60 * 1000)
  return d
}

export const formatDate = (date: Date) => {
  return format(date, 'doM.y', {
    locale: de
  })
}

export const formatTime = (date: Date) => {
  return format(date, 'HH:mm', {
    locale: de
  })
}

export const formatDateRange = (start: string, end: string) => {
  const startParsed = parseDate(start)
  const endParsed = parseDate(end)

  const startDate = {
    date: formatDate(startParsed),
    time: formatTime(startParsed)
  }
  const endDate = {
    date: formatDate(endParsed),
    time: formatTime(endParsed)
  }

  return `${startDate.date} ${startDate.time} - ${endDate.date !== startDate.date ? endDate.date : ''} ${endDate.time}`
}

export const transformStaticPath = (path: string) => {
  const filename = path.split('/').pop()
  return '/assets/' + filename
}

export const stripHtml = (html: string) => html.replace(/(<([^>]+)>)/gi, '')

export const sortByDateProperty = <T extends {[key: string]: any}[]>(array: T, property: string, order: 'ASC' | 'DESC' = 'DESC') => {
  const sorted = array.sort((a, b) => new Date(b[property]).getTime() - new Date(a[property]).getTime())
  return (order === 'ASC' ? sorted.reverse() : sorted) as T
}

export const nl2jsx = (text: string) => text.split('\n').map(line => <Fragment key={line}>{line}<br /></Fragment>)
