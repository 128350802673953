import useEmblaCarousel from 'embla-carousel-react'
import React, { useEffect, useMemo } from 'react'
import * as styles from './Carousel.module.scss'

interface CarouselProps {
  slides: React.ReactNode[]
}

const Carousel = ({ slides }: CarouselProps) => {
  const emblaConfig = useMemo(() => ({
    loop: true,
    draggable: false,
  }), [slides])

  const [emblaRef, embla] = useEmblaCarousel(emblaConfig)

  useEffect(() => {
    const id = setInterval(() => {
      if (!document.hidden)
        embla?.scrollNext()
    }, 5000)
    return () => clearInterval(id)
  }, [embla])

  return (
    <div className={styles.carouselWrapper}>
      <div className={styles.viewport} ref={emblaRef}>
        <div className={styles.container}>
          {slides.map((slide, slideIndex) => (
            <div key={slideIndex} className={styles.slide}>
              {slide}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Carousel
