import React from 'react'
import { Link } from 'gatsby-plugin-intl'
import ROUTES from './routes'
import { useIntl } from 'gatsby-plugin-intl'
import * as styles from './Footer.module.scss'

const Footer = () => {
  const { formatMessage } = useIntl()

  return (
    <footer className={styles.footer}>
      <Link to={ROUTES.IMPRESSUM}>{formatMessage({id: 'pageLinkTitles.IMPRESSUM'})}</Link>
      <Link to={ROUTES.DATENSCHUTZ}>{formatMessage({id: 'pageLinkTitles.DATENSCHUTZ'})}</Link>
    </footer>
  )
}

export default Footer
