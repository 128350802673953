import React, { useEffect } from 'react'
import '../scss/global.scss'
import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/700.css'
import Modal from 'react-modal'
import { IGatsbyImageData } from 'gatsby-plugin-image'

import Navigation from './Navigation'
import HeroSection from './HeroSection'
import Footer from './Footer'
import SEO, { SeoProps } from './SEO'

import * as styles from './Layout.module.scss'

Modal.setAppElement('#___gatsby')

interface LayoutProps {
  children: React.ReactNode;
  hero?: HeroImageData<IGatsbyImageData>;
  introCard?: IntroCard;
  wide?: boolean;
  seo: SeoProps;
}

const Layout = ({ children, hero, introCard, wide, seo }: LayoutProps) => {
  useEffect(() => {
    // Smooth anchor scrolling
    const anchorLinks: NodeListOf<HTMLAnchorElement> = document.querySelectorAll(`a[href^='#']`)
    anchorLinks.forEach(el => {
      el.addEventListener('click', e => {
        e.preventDefault()
        const hash = '#' + el.href.split('#')[1]
        const target = document.querySelector(hash)

        if (history.pushState) {
          history.pushState(null, '', hash)
        } else {
          location.hash = hash
        }

        if (hash.length > 1) {
          target?.scrollIntoView({
            behavior: 'smooth'
          })
        } else {
          window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
          })
        }
      })
    })
  }, [])

  return (<>
    <SEO {...seo} />
    <Navigation />
    <HeroSection hero={hero} introCard={introCard} />
    <main className={[styles.content, wide && styles.wide].join(' ')}>
      {children}
    </main>
    <Footer />
  </>)
}

export default Layout
