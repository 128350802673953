import React, { Fragment, useCallback, useState } from 'react'
import { Link as GatsbyLink } from 'gatsby'
import { Link, IntlContextConsumer, changeLocale, useIntl } from 'gatsby-plugin-intl'
import ROUTES from './routes'
import DrachenbergDentalLaborLogo from '../assets/drachenberg-dental-labor-logo.svg'
import ExternalLinkIcon from '../assets/external-link.svg'
import * as styles from './Navigation.module.scss'

interface IntlContextConsumerFunctionProps {
  languages: string[]
  language: string
  originalPath: string
}

const ExternalIcon = () => <ExternalLinkIcon className={styles.externalIcon} />

const Navigation = () => {
  const { formatMessage } = useIntl()
  const [showHamburger, setShowHamburger] = useState(false)

  const handleLangSwitch = useCallback((e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, language: string) => {
    e.preventDefault()
    changeLocale(language)
  }, [])

  return (
    <div className={[styles.navigation, showHamburger ? styles.showHamburger : undefined].join(' ')}>
      <Link to={ROUTES.HOME} className={styles.logoLink}>
        <DrachenbergDentalLaborLogo className={styles.logo}/>
      </Link>
      <nav>
        <Link activeClassName={styles.active} to={ROUTES.UNTERNEHMEN}>{formatMessage({id: 'pageLinkTitles.UNTERNEHMEN'})}</Link>
        <Link activeClassName={styles.active} to={ROUTES.LEISTUNGEN}>{formatMessage({id: 'pageLinkTitles.LEISTUNGEN'})}</Link>
        <Link activeClassName={styles.active} to={ROUTES.DENTAL_KURSE}>{formatMessage({id: 'pageLinkTitles.DENTAL_KURSE'})}</Link>
        <Link activeClassName={styles.active} to={ROUTES.NEWS}>{formatMessage({id: 'pageLinkTitles.NEWS'})}</Link>
        <a href={ROUTES.ACADEMY} target='_blank'>{formatMessage({id: 'pageLinkTitles.ACADEMY'})} <ExternalIcon /></a>
        <a href={ROUTES.SHOP} target='_blank'>{formatMessage({id: 'pageLinkTitles.SHOP'})} <ExternalIcon /></a>
        <Link activeClassName={styles.active} to={ROUTES.KONTAKT}>{formatMessage({id: 'pageLinkTitles.KONTAKT'})}</Link>
        <div>
          <IntlContextConsumer>
            {({ languages, language: currentLocale, originalPath }: IntlContextConsumerFunctionProps) =>
              languages.map((language, langIndex) => (<Fragment key={language}>
                <GatsbyLink
                  to={'/' + language + originalPath}
                  lang={language}
                  className={language === currentLocale ? styles.active : undefined}
                  onClick={e => handleLangSwitch(e, language)}
                >{language.toUpperCase()}</GatsbyLink>
                {langIndex + 1 < languages.length && ' | '}
              </Fragment>))
            }
          </IntlContextConsumer>
        </div>
      </nav>

      <button className={styles.hamburgerBtn} onClick={() => setShowHamburger(!showHamburger)}>
        <span></span>
        <span></span>
        <span></span>
      </button>
    </div>
  )
}

export default Navigation
