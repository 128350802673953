import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Helmet } from 'react-helmet'
import { stripHtml } from '../utils'
import { useIntl } from "gatsby-plugin-intl"

export interface SeoProps {
  title: string;
  description: string;
}

const SEO = ({ title, description }: SeoProps) => {
  const { locale } = useIntl()
  const { site } = useStaticQuery(query)
  const fullTitle = title ? (title + ' | ' + site.siteMetadata.defaultTitle) : site.siteMetadata.defaultTitle

  return (
    <Helmet
      title={fullTitle}
      htmlAttributes={{ lang: locale }}
    >
      <meta name='description' content={stripHtml(description)}/>

      <link rel='apple-touch-icon' sizes='180x180' href='/apple-touch-icon.png' />
      <link rel='icon' type='image/png' sizes='32x32' href='/favicon-32x32.png' />
      <link rel='icon' type='image/png' sizes='16x16' href='/favicon-16x16.png' />
      <link rel='shortcut icon' href='/favicon.ico' />
    </Helmet>
  )
}

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
      }
    }
  }
`

export default SEO
