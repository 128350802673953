import React, { useMemo } from 'react'
import { GatsbyImage, getImage, IGatsbyImageData, withArtDirection } from 'gatsby-plugin-image'
import * as styles from './HeroSection.module.scss'
import Button from './Button'
import Carousel from './Carousel'

interface HeroImageProps {
  hero?: HeroImageData<IGatsbyImageData>;
  introCard?: IntroCard
}

const HeroSection = ({ hero, introCard }: HeroImageProps) => {

  const sliderImages = useMemo(() => {
    return hero?.map(({ image }) => {
      const desktopHero = image?.desktopHero && getImage(image.desktopHero)
      const mobileHero = image?.mobileHero && getImage(image.mobileHero)
      const heroImages = desktopHero && mobileHero && withArtDirection(desktopHero, [
        {
          media: '(max-width: 760px)',
          image: mobileHero,
        },
      ])

      return heroImages
    })
  }, [hero])

  return (
    <div className={[styles.heroSection, hero && styles.hasHero].join(' ')}>
      <div className={styles.headerSpacer} />
      {sliderImages && <div className={styles.heroWrapper}>
        <Carousel
          slides={sliderImages.map(heroImages => {
            return heroImages && <GatsbyImage
              image={heroImages}
              alt=''
              className={styles.hero}
            />
          })}
        />
      </div>}
      {introCard && <div className={styles.introCardColumn}>
        <div className={styles.introCard}>
          <h1>{introCard.title}</h1>
          <div dangerouslySetInnerHTML={{__html: introCard.body}}></div>
          {introCard.link && (
            <Button
              href={introCard.link.href}
              align='stretch'
              icon='link'
              iconPosition='end'
              noMarginBottom
            >{introCard.link.label}</Button>
          )}
        </div>
      </div>}
    </div>
  )
}

export default HeroSection
